<template lang="pug">
  .period-specified-fee
    .price-input-container(
      v-for="(specifiedFee, index) in item.fees.$each.$iter"
      :key="index"
    )
      AppNumberInput(
        v-model="specifiedFee.day.$model"
        :invalid="specifiedFee.day.$error"
      )
      span.title {{ $t("plan_settings.basic_settings.before") }}
      AppNumberInput(
        v-model="specifiedFee.fee.$model"
        :invalid="specifiedFee.fee.$error"
        :max="Number.POSITIVE_INFINITY"
      )
      span.yen {{ $t("plan_settings.basic_settings.currency") }}
      .remove
        AppIconButton.icon(
          v-if="item.fees.$model.length != 1"
          icon="trash-alt"
          @click="$emit('add-item', index)"
        )
      .add-new
        AppIconButton.icon(
          v-if="index == item.fees.$model.length - 1 && item.fees.$model.length <= 5"
          icon="plus-circle"
          @click="$emit('remove-item')"
        )
</template>

<script>
  export default {
    components: {
      AppNumberInput: () => import("@/components/elements/AppNumberInput"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"

  .period-specified-fee
    padding-inline: 8px

    .price-input-container
      +fees-container

      .title
        text-align: center

      ::v-deep
        .app-input
          min-width: 50px

    .remove,
    .add-new
      .icon
        +icon-button($default-purple)
        user-select: none
        // text-align: center
        // width: 15px

        // svg
        //   color: $default-purple
        //   cursor: pointer
        //   -webkit-touch-callout: none
        //   -webkit-user-select: none
        //   -khtml-user-select: none
        //   -moz-user-select: none
        //   -ms-user-select: none
</style>
